define("discourse/plugins/discourse-jira/discourse/components/fields/dj-field-label", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.label}}
    <label class="control-label">
      <span>
        {{this.label}}
        {{#if this.field.required}}
          *
        {{/if}}
      </span>
    </label>
  {{/if}}
  */
  {
    "id": "J95KNJtD",
    "block": "[[[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"],[41,[30,0,[\"field\",\"required\"]],[[[1,\"        *\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-jira/discourse/components/fields/dj-field-label.hbs",
    "isStrictMode": false
  });
  class FieldLabel extends _component.default {
    field = null;
    label = null;
  }
  _exports.default = FieldLabel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FieldLabel);
});
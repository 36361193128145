define("discourse/plugins/discourse-jira/discourse/components/fields/dj-textarea-field", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field text-field">
    <div class="control-group">
      {{fields/dj-field-label label=this.label field=this.field}}
  
      <div class="controls">
        <div class="field-wrapper">
          {{d-textarea
            value=this.field.metadata.value
            input=(action (mut this.field.metadata.value) value="target.value")
          }}
        </div>
      </div>
    </div>
  </section>
  */
  {
    "id": "VOHOV7T7",
    "block": "[[[10,\"section\"],[14,0,\"field text-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"field-wrapper\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"value\",\"input\"],[[30,0,[\"field\",\"metadata\",\"value\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"field\",\"metadata\",\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"fields/dj-field-label\",\"d-textarea\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-jira/discourse/components/fields/dj-textarea-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "dj-textarea-field"));
});
define("discourse/plugins/discourse-jira/discourse/components/fields/dj-date-field", ["exports", "@ember/component", "@ember/object", "discourse/plugins/discourse-jira/discourse/components/fields/dj-base-field", "@ember/template-factory"], function (_exports, _component, _object, _djBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field date-field">
    <div class="control-group">
      {{fields/dj-field-label label=this.label field=this.field}}
  
      <div class="controls">
        <div class="controls-row">
          {{input
            type="date"
            value=(readonly this.localTime)
            input=(action "convertToUniversalTime" value="target.value")
          }}
  
          {{#if this.field.value}}
            {{d-button
              icon="trash-alt"
              action=(action (mut this.field.value) value=null)
            }}
          {{/if}}
        </div>
      </div>
    </div>
  </section>
  */
  {
    "id": "hHfASbIX",
    "block": "[[[10,\"section\"],[14,0,\"field date-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls-row\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"type\",\"value\",\"input\"],[\"date\",[28,[37,2],[[30,0,[\"localTime\"]]],null],[28,[37,3],[[30,0],\"convertToUniversalTime\"],[[\"value\"],[\"target.value\"]]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"field\",\"value\"]],[[[1,\"          \"],[1,[28,[35,5],null,[[\"icon\",\"action\"],[\"trash-alt\",[28,[37,3],[[30,0],[28,[37,6],[[30,0,[\"field\",\"value\"]]],null]],[[\"value\"],[null]]]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"fields/dj-field-label\",\"input\",\"readonly\",\"action\",\"if\",\"d-button\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-jira/discourse/components/fields/dj-date-field.hbs",
    "isStrictMode": false
  });
  class DateField extends _djBaseField.default {
    convertToUniversalTime(date) {
      return date && this.set("field.value", moment(date).utc().format());
    }
    static #_ = (() => dt7948.n(this.prototype, "convertToUniversalTime", [_object.action]))();
    get localTime() {
      return this.field.value && moment(this.field.value).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
    static #_2 = (() => dt7948.n(this.prototype, "localTime", [(0, _object.computed)("field.metadata.value")]))();
  }
  _exports.default = DateField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DateField);
});
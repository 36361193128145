define("discourse/plugins/discourse-jira/discourse/components/fields/dj-text-field", ["exports", "@ember/component", "discourse/plugins/discourse-jira/discourse/components/fields/dj-base-field", "@ember/template-factory"], function (_exports, _component, _djBaseField, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field text-field">
    <div class="control-group">
      {{fields/dj-field-label label=this.label field=this.field}}
  
      <div class="controls">
        <div class="field-wrapper">
          {{input
            value=this.field.value
            required=this.field.required
            input=(action (mut this.field.value) value="target.value")
          }}
        </div>
      </div>
    </div>
  </section>
  */
  {
    "id": "o9fwwT57",
    "block": "[[[10,\"section\"],[14,0,\"field text-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"label\",\"field\"],[[30,0,[\"label\"]],[30,0,[\"field\"]]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"field-wrapper\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"value\",\"required\",\"input\"],[[30,0,[\"field\",\"value\"]],[30,0,[\"field\",\"required\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"field\",\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"fields/dj-field-label\",\"input\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-jira/discourse/components/fields/dj-text-field.hbs",
    "isStrictMode": false
  });
  class TextField extends _djBaseField.default {}
  _exports.default = TextField;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextField);
});